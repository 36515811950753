// # 
// # Carousel Effect Demo Colors
// ##############################

#{$effect-class}__carousel
  background: rgba(#79FF00, 0.2)
  #{$inner-class}
    background: #83C745
  a
    color: #83C745
