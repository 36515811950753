// breakpoints
$S: 480px
$M: 768px
$L: 1170px
$maxL: $L - 1px

// media queries
// #################

=MQ($canvas)
  @if $canvas == S
    @media only screen and (max-width: $S)
      @content
  @else if $canvas == M
    @media only screen and (max-width: $M)
      @content
  @else if $canvas == L
    @media only screen and (max-width: $L)
      @content

// super light grid - it works with the .cd-container class inside style.scss

=column($percentage, $float-direction: left)
  width: 100% * $percentage
  float: $float-direction

=scrollbar()
  &::-webkit-scrollbar
    background: $brown
    width: 5px
  &::-webkit-scrollbar-thumb
    background: $yellow
