// # 
// # Wave Effect Demo Colors
// ##############################

#{$effect-class}__wave
  background: rgba(#B3E099, 0.2)
  #{$inner-class}
    background: #028F76
  a
    color: #028F76
