// # 
// # Cube Effect Demo Colors
// ##############################

#{$effect-class}__cube
  background: rgba(#56AEE7, 0.3)
  #{$inner-class}
    background: #56AEE7
  a
    color: #56AEE7
