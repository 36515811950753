#lukeed
  height: 0
  font-size: 0
  position: fixed
  right: 60px
  bottom: 80px
  span
    display: block
    background: transparent url("/assets/img/lukeed.png") no-repeat
    background-size: 100%
    width: 50px
    height: 40px
    opacity: 0.4
    margin: 0 auto
    transition: opacity 0.3s ease-in-out
  &:hover span
    opacity: 1
  +MQ(M)
    position: relative
    bottom: auto
    right: auto
