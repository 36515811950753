// @ Top of each example
//

.intro-text
	padding: 60px 10px 40px
	text-align: center

	h1
		font-size: 3em

	h3
		max-width: 650px
		margin: 0 auto
