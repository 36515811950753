// # 
// # Spiral-Left Effect Demo Colors
// ##############################

#{$effect-class}__spiral-left
  background: rgba(#FFEAAD, 0.2)
  #{$inner-class}
    background: #B3E099
  a
    color: #B3E099
