// # 
// # Arc Effect Demo Colors
// ##############################

#{$effect-class}__arc
  background: rgba(#E9D558, 0.2)
  #{$inner-class}
    background: #FFAB07
  a
    color: #FFAB07
