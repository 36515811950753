.homepage
  position: relative
  text-align: center
  font-family: Oxygen
  height: 100vh

  header
    margin: 0px auto 50px
    padding-top: 30px

    img
      margin-bottom: -20px

    h4 
      font-size: 1.4em
    a
      padding: 0 12.5px

.examples
  max-width: 250px
  margin: 0 auto
  text-align: left

  h3
    font-size: 1.8em
    text-align: center
    margin-bottom: 1em
    text-decoration: underline

  ul
    font-weight: 600
    margin: 20px auto
    li
      padding-bottom: 10px
    a, dt
      font-size: 1.6em
    dt
      color: $brown
      margin-bottom: -15px
    ul
      margin-left: 30px
      li
        padding-bottom: 6px

