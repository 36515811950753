// # 
// # Classic Effect Demo Colors
// ##############################

#{$effect-class}__classic
  background: rgba(#F36F56, 0.2)
  #{$inner-class}
    background: #F36F56
  a
    color: #F36F56
