// # 
// # Concave Effect Demo Colors
// ##############################

#{$effect-class}__concave
  background: rgba(#EB9CB8, 0.3)
  #{$inner-class}
    background: #EB9CB8
  a
    color: #EB9CB8
